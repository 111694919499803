import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import WindowTitle from "../../components/WindowTitle/WindowTitle";
import privateRequest from "../../services/api.config";
import { put } from "../../services/api.request";

const EditAgileParts = ({
  setEditWindow,
  user_id,
  partDetails,
  reloadGrid,
}) => {
  const [editLoading, setEditLoading] = useState(false);
  const [commCodeLoading, setCommCodeLoading] = useState(false);
  const [commCodesList, setCommCodesList] = useState([]);
  const [commGroupLoading, setCommGroupLoading] = useState(false);
  const [commGroupList, setCommGroupList] = useState([]);
  const [addDetails, setAddDetails] = useState({
    grpCheck: false,
    codeCheck: false,
    grpText: "",
    codeText: "",
    commGroupLov: "",
    commCodesLov: "",
    commodity_code: "",
    commodity_group: "",
    part_desc: partDetails.part_desc,
    part_number: partDetails.part_number,
  });

  const getCommCodes = debounce(({ filter }) => {
    setCommCodeLoading(true);
    let url = `/generic_lov_service/commoditycode/?search_for=${filter.value}`;
    return privateRequest
      .get(url)
      .then(({ data }) => {
        setCommCodesList(data.items);
        setCommCodeLoading(false);
      })
      .catch((err) => {
        setCommCodeLoading(false);
      });
      
  }, 1000);

  const getCommGroup = debounce(({ filter }) => {
    setCommGroupLoading(true);
    let url = `/generic_lov_service/commoditygroup/?search_for=${filter.value}`;
    return privateRequest
      .get(url)
      .then(({ data }) => {
        setCommGroupList(data.items);
        setCommGroupLoading(false);
      })
      .catch((err) => {
        setCommGroupLoading(false);
      });
  }, 1000);

  useEffect(() => {
    setAddDetails({
      ...addDetails,
      commCodesLov:
        partDetails.comm_code_flg === "E"
          ? {
              lookup_type: "commoditycode",
              lookup_value: partDetails.commodity_code,
            }
          : "",
      commGroupLov:
        partDetails.comm_group_flg === "E"
          ? {
              lookup_type: "commoditygroup",
              lookup_value: partDetails.commodity_group,
            }
          : "",
      part_number: partDetails.part_number,
      part_desc: partDetails.part_desc,
      grpText:
        partDetails.comm_group_flg === "N" &&
        partDetails.commodity_group !== null
          ? partDetails.commodity_group
          : "",
      codeText:
        partDetails.comm_code_flg === "N" && partDetails.commodity_code !== null
          ? partDetails.commodity_code
          : "",
      commodity_code: partDetails.commodity_code,
      commodity_group: partDetails.commodity_group,
      codeCheck:
        partDetails.comm_code_flg === "N" &&
        partDetails.commodity_group !== null
          ? true
          : false,
      grpCheck:
        partDetails.comm_group_flg === "N" &&
        partDetails.commodity_group !== null
          ? true
          : false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    let params = {
      part_desc: addDetails.part_desc,
      part_number: addDetails.part_number,
      commodity_code: addDetails.commodity_code,
      commodity_group: addDetails.commodity_group,
    };
    setEditLoading(true);
    put(`agile_flash_ccode_update?p_user_id=${user_id}`, params, saveAgileData);
  };

  const saveAgileData = ({ data }) => {
    data.p_out_status === "Success"
      ? alert("Saved Successfully.")
      : alert(data.p_out_err_msg);
    setEditWindow(false);
    setEditLoading(false);
    reloadGrid();
  };

  const handleClear = () => {
    setAddDetails({
      grpCheck: false,
      codeCheck: false,
      grpText: "",
      codeText: "",
      commGroupLov: "",
      commCodesLov: "",
    });
  };

  const handleChangeOfItems = (e, item) => {
    switch (item) {
      case "grpLov":
        setAddDetails({
          ...addDetails,
          commGroupLov: e.value,
          commodity_group: e.value.lookup_value,
        });
        break;
      case "codeLov":
        setAddDetails({
          ...addDetails,
          commCodesLov: e.value,
          commodity_code: e.value.lookup_value,
        });
        break;
      case "grpCheck":
        setAddDetails({
          ...addDetails,
          grpCheck: e.value,
          grpText: "",
          commGroupLov: "",
        });
        break;
      case "codeCheck":
        setAddDetails({
          ...addDetails,
          codeCheck: e.value,
          codeText: "",
          commCodesLov: "",
        });
        break;
      case "grpText":
        setAddDetails({
          ...addDetails,
          grpText: e.value,
          commodity_group: e.value,
        });
        break;
      case "codeText":
        setAddDetails({
          ...addDetails,
          codeText: e.value,
          commodity_code: e.value,
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className="window-container">
      <Window
        style={{ width: "450px", height: "auto" }}
        title={<WindowTitle title="Edit Agile Parts" icon="k-i-edit" />}
        onClose={() => setEditWindow(false)}
        minimizeButton={() => null}
        maximizeButton={() => null}
      >
        <React.Fragment>
          <div style={{ marginTop: "-15px" }}>
            {editLoading ? <ProgressBar /> : ""}
          </div>
          <div className="agileEdit">
            <label className="input-header-cell">Commodity Group:</label>
            <label className="input-header-cell">Commodity Code:</label>
            <DropDownList
              data={commGroupList}
              filterable={true}
              textField="lookup_value"
              dataItemKey="lookup_value"
              onFilterChange={getCommGroup}
              loading={commGroupLoading}
              value={addDetails.commGroupLov}
              onChange={(e) => handleChangeOfItems(e, "grpLov")}
              disabled={addDetails.grpCheck === true ?? false}
            />
            <DropDownList
              data={commCodesList}
              filterable={true}
              textField="lookup_value"
              dataItemKey="lookup_value"
              onFilterChange={getCommCodes}
              loading={commCodeLoading}
              value={addDetails.commCodesLov}
              onChange={(e) => handleChangeOfItems(e, "codeLov")}
              disabled={
                addDetails.codeCheck === true ||
                addDetails.grpText !== "" ||
                (addDetails.grpText === "" && addDetails.commGroupLov === "")
                  ? true
                  : false
              }
            />
            <Checkbox
              value={addDetails.grpCheck}
              onChange={(e) => handleChangeOfItems(e, "grpCheck")}
              label="New Commodity Group"
              disabled={addDetails.commGroupLov !== ""}
            />
            <Checkbox
              value={addDetails.codeCheck || addDetails.grpText !== ""}
              onChange={(e) => handleChangeOfItems(e, "codeCheck")}
              label="New Commodity Code"
              disabled={
                addDetails.grpText !== "" ||
                (addDetails.grpText === "" && addDetails.commGroupLov === "")
              }
            />
            <Input
              style={{
                margin: 0,
                border: addDetails.grpCheck && "solid 0.3px",
              }}
              value={addDetails.grpText}
              type="string"
              onChange={(e) => handleChangeOfItems(e, "grpText")}
              disabled={addDetails.grpCheck === true ? false : true}
            />
            <Input
              style={{
                margin: 0,
                border:
                  (addDetails.codeCheck || addDetails.grpText !== "") &&
                  "solid 0.3px",
              }}
              type="string"
              value={addDetails.codeText}
              onChange={(e) => handleChangeOfItems(e, "codeText")}
              disabled={
                addDetails.codeCheck === true || addDetails.grpText !== ""
                  ? false
                  : true
              }
            />
          </div>
          <div style={{ float: "right" }}>
            <Button
              onClick={handleClear}
              className="action-button"
              disabled={
                addDetails.grpText === "" &&
                addDetails.commGroupLov === "" &&
                addDetails.codeText === "" &&
                addDetails.commCodesLov === ""
              }
            >
              Clear
            </Button>
            <Button
              onClick={handleSave}
              icon="save"
              className="action-button"
              disabled={
                (addDetails.grpText === "" && addDetails.commGroupLov === "") ||
                (addDetails.codeText === "" && addDetails.commCodesLov === "")
              }
            >
              Save
            </Button>
          </div>
        </React.Fragment>
      </Window>
    </div>
  );
};

export default EditAgileParts;

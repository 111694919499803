import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import jwt_decode from "jwt-decode";

import Home from "./pages/Home/Home";
import "@progress/kendo-theme-default/dist/all.css";
import GcmToCommodityCodeMapping from "./pages/gcm-commodity-code-mapping/GcmToCommodityCodeMapping";
import QuaterlyCostSubmisson from "./pages/QuarterlyCostSubmission/QuarterlyCostSubmission";
import CommodityCodeScreen from "./pages/commodityCodeScreen/CommodityCodeScreen";
import ShouldBeCost from "./pages/shouldBeCost/shouldBeCost";
import TopDownDiscount from "./pages/TopDownDiscount/TopDownDiscount";
import CostAllocation from "./pages/CostAllocation/costAllocation"
import CostSubmission from "./pages/CostSubmissionView/costSubmission";
import { getRequest} from './services/api.request';
import PartNumTOGrp from "./pages/partNumToGrp/partnumToGrp";

import SideNavList  from './layout/sideNavList';
import "./App.css";
import ProgressBar from "./components/ProgressBar/ProgressBar";
import Callback from "./auth/Callback";
import { Switch } from "react-router-dom";
import PrivateRoute from "./auth/PrivateRoute";
import OneTimeCharge from "./pages/OneTimeCharge/OneTimeCharge";
import ReductionInitative from './pages/ReductionInitative/ReductionInitative';
import HeirarchyGroupParts from "./pages/HeirarchyGroupParts/HeirarchyGroupParts";
import AgileParts from "./pages/AgileParts/agileParts"

export const UserContext = createContext();

function App() {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentUserCommCodes, setCurrentUserCommCodes] = useState([]);


  useEffect(()=>{
    let userId = "";
    let idp_token = localStorage.getItem("idp_token");
    if(idp_token){
      let { username } = jwt_decode(idp_token);
     userId = username.split("_")[1]
    }
    let path = `/cmat_user/${userId}`;

    const getMappedCommodityCodes = ({ data }, type) => {
      let status = data?.status;
      if(status == "200"){
        let res = data?.data?.items;
        if(res?.length > 0){
          res.forEach((obj) => {
            if(obj?.gcc_end_active == null || obj?.gcc_end_active == "" || (obj?.gcc_end_active != null && isDateInRange(obj?.gcc_start_active, obj?.gcc_end_active))){
              setCurrentUserCommCodes(prevState => {
                return [...prevState, obj.commodity_code]
              })
            }
          })
        }
      }
      setLoading(false);
    }

    const getGcms = ({ data, user_name }, type)=>{
      let status = data?.status;
      if(status == "200"){
        let gcmGroups = data?.data?.items;
        let currentUserGcgIds = [];
        gcmGroups.forEach((group) => {
          if(group.user_name == user_name){
            currentUserGcgIds.push(group.gcg_id);
          }
        })
        if(currentUserGcgIds?.length > 0){
          setLoading(true);
          currentUserGcgIds.forEach(async (code) => {
           await getRequest('/gcm_group_ccode/'+code+'?offset=0',(data) => getMappedCommodityCodes({data}));
          })
        } else{
          setLoading(false);
        }
      }
    };

    const getAllGcmGroup = async (user_name) => {
      await getRequest(`/gcm_group/?user_name=${user_name.replace(/\s+/g, "+")}`,(data) => getGcms({data, user_name}));
    }

    const handleResponse = async (response) => {
      let { items } = response.data;
      if(items && items.length > 0){
        if(!items[0]?.user_commodity_codes){
          await getAllGcmGroup(items[0]?.user_name);
        } 
        setUser({...items[0], user_id: userId})
      }
    }
    getRequest(path, handleResponse);

  },[])


  useEffect(() => {
    if(currentUserCommCodes?.length > 0){
      setUser(prevState => {return {...prevState,"user_commodity_codes": currentUserCommCodes}})
    }
  },[currentUserCommCodes])


  const isDateInRange = (startDateString, endDateString) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    const today = new Date();
  
    return startDate <= today && today <= endDate;
  }

  return (
    <React.Fragment>
      <UserContext.Provider value={user}>
        {
            loading ? <ProgressBar /> : ""
        }
        <BrowserRouter>
          <Switch>
            <Route exact path="/saml/callback" component={Callback}/>
            <SideNavList>
              <Route exact={true} path="/" component={Home} />
              <PrivateRoute path="/GCM" component={GcmToCommodityCodeMapping}></PrivateRoute> 
              <PrivateRoute path="/AgileParts" component={AgileParts}></PrivateRoute>
              <PrivateRoute path="/Commodity" component={CommodityCodeScreen}></PrivateRoute> 
              <PrivateRoute path="/ShouldBeCost" component={ShouldBeCost}></PrivateRoute> 
              <PrivateRoute path="/TopDownDiscount" component={TopDownDiscount}></PrivateRoute> 
              <PrivateRoute path="/QuarterlySubmission" component={QuaterlyCostSubmisson}></PrivateRoute> 
              <PrivateRoute path="/CostSubmission" component={CostSubmission}></PrivateRoute> 
              <PrivateRoute path="/PartNumToGrp" component={PartNumTOGrp}></PrivateRoute> 
              <PrivateRoute path="/CostAllocation" component={CostAllocation}></PrivateRoute> 
              <PrivateRoute path="/OneTimeCharge" component={OneTimeCharge}></PrivateRoute>
              <PrivateRoute path="/ROCOGSReduction" component={ReductionInitative}></PrivateRoute>
              <PrivateRoute path="/hierarchies" component={HeirarchyGroupParts}></PrivateRoute>
            </SideNavList>
          </Switch>
        </BrowserRouter>
      </UserContext.Provider>
    </React.Fragment>
  );
}
export default App;

export const SCREEN_METADATA = [
  {
    text: "GCM to Commodity Code Assignment",
    icon: "k-i-user",
    route: "/GCM",
    title: "GCM to Commodity Code Assignment",
    roles_allowed:"is_gcm_admin"
  },
  {
    icon: "k-i-file-add",
    route: "/AgileParts",
    text: "Agile Parts",
    title: "Agile Parts",
    roles_allowed:"is_gcm_admin"
  },
  {
    text: "Commodity Code View",
    icon: "k-i-delicious",
    route: "/Commodity",
    title: "Commodity Code View",
    roles_allowed:"is_gcm_admin,is_scm_admin"
  },
  {
    text: "CMAT Calendar",
    icon: "k-i-calendar",
    route: "/QuarterlySubmission",
    title: "CMAT Calendar",
    roles_allowed:"is_scm_admin"
  },
  {
    text: "Should Cost",
    icon: "k-i-calculator",
    route: "/ShouldBeCost",
    title: "Should Cost",
    roles_allowed:"is_scm_admin,is_scm_user"
  }, 
  {
    text: "Hierarchies",
    icon: "k-i-flip-horizontal",
    route: "/hierarchies",
    title: "Hierarchies",
    roles_allowed:"is_gcm_user,is_gcm_admin"
  },
  {
    text: "Group Part Numbers",
    icon: "k-i-ungroup",
    route: "/PartNumToGrp",
    title: "Group Part Numbers",
    roles_allowed:"is_gcm_user"
  },
  {
    text: "Cost Submission",
    icon: "k-i-table-cell",
    route: "/CostSubmission",
    title: "Cost Submission",
    roles_allowed:"is_gcm_user"
  },
  {
    text: "Cost Allocation",
    icon: "k-i-table k-i-table-unmerge",
    route: "/CostAllocation",
    title: "Cost Allocation",
    roles_allowed:"is_gcm_user"
  },
  {
    text: "Top Down Discount",
    icon: "k-i-percent",
    route: "/TopDownDiscount",
    title: "Top Down Discount",
    roles_allowed:"is_gcm_user"
  },
  {
    icon: "k-i-star",
    route: "/OneTimeCharge",
    text: "One Time Charge",
    title: "One Time Charge",
    roles_allowed:"is_gcm_user,is_scm_admin"
  },
  {
    icon: "k-i-undo",
    route: "/ROCOGSReduction",
    text: "R&O COGS Reduction Initative",
    title: "RO COGS Reduction Initative",
    roles_allowed:"is_gcm_user,is_scm_admin"
  }
]

import React, { useEffect, useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import * as _ from 'lodash'

import './SideNavList.css'
import Header from "./Header/Header";
import { SCREEN_METADATA } from "../configs/screenMetaData";
import PageHeader from "../components/PageHeader/PageHeader";
import { ssoUrl } from "../environment/environment.dev";
import SecurityImage from '../assets/security.png'
import { UserContext } from "../App";

const SideNavList = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  const user = useContext(UserContext);
  const [items, setItems] = useState([]);
  const [isAuthenticated, setAuthenticated] = React.useState(false);
  let menus = [{
    text: "Home",
    icon: "k-i-home",
    route: "/",
    title: "Home",
  }
    , ...SCREEN_METADATA];

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const selectDrawerContent = (ev) => {
    props.history.push(ev.itemTarget.props.route);
    setExpanded(false);
  };

  let checkAuth = () => {
    let idp_token = localStorage.getItem("idp_token");
    if (!idp_token) {
      window.location.replace(ssoUrl, { replace: true })
    } else {
      setAuthenticated(true);
    }
  }
  useEffect(() => {
     checkAuth();  
  }, [])
useEffect(()=>{
  let filteredItem = [];
    menus.forEach((item) => {
      if(user[`scr_${item.title.toLowerCase()}`] === "N") {
        return
      }
      if (item.hasOwnProperty("roles_allowed")) {
        let roles_allowed = item["roles_allowed"].split(",");
        roles_allowed.forEach((role) => {
          if (user.hasOwnProperty(role) && (user[role] === "Y")) {
            filteredItem.push(item)
          }
        })
      } else {
        filteredItem.push(item)
      }
    })
    setItems(_.uniq(filteredItem));
},[user])
  const setSelectedItem = (pathName) => {
    let currentPath = items.find((item) => item.route === pathName);
    if (currentPath && currentPath.text) {
      return currentPath.text;
    }
  };
  const selected = setSelectedItem(props.location.pathname);
  return (
    <React.Fragment>
      {
        isAuthenticated ?
          <Drawer
            expanded={expanded}
            position={'start'}
            mode={'push'}
            mini={true}
            items={items.map((item) => ({
              ...item,
              selected: item.text === selected,
            }))}
            onSelect={selectDrawerContent}
          >
            <DrawerContent style={{ width: '95vw' }}>
              <Header handleClick={handleClick} />
              <PageHeader selected={selected} />
              {props.children}
            </DrawerContent>
          </Drawer>
          :
          <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100vw' }}>
            <img src={SecurityImage} alt="security" width={200} height={200} />
            <p>Redirecting...</p>
          </div>
      }
    </React.Fragment>
  );
};
export default withRouter(SideNavList);

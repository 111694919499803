import { getter, orderBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { getSelectedState } from "@progress/kendo-react-data-tools";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useCallback, useEffect, useRef, useState, useContext } from "react";
import FilterPopUp from "../../components/FilterPopUp/FilterPopUp";
import GridCellToolTip from "../../components/GridCellToolTip/GridCellToolTip";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import EditAgileParts from "./editAgileParts";
import "./agileParts.css";
import { getRequest, put } from "../../services/api.request";
import { formatDates } from "../../utils/formatDateUtil";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { UserContext } from "../../App";

const initialDataState = {
  skip: 0,
  take: 10,
};

const FILTER_PARAMS = [
  {
    paramName: "part_number_agile",
    displayValue: "Part Numbers",
    id_field: "part_number",
    api_field: "part_number",
  },
];

const AgileParts = () => {
  const limit = 10;
  const DATA_ITEM_KEY = "part_number";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);
  const userContext = useContext(UserContext);
  const user_id = userContext.user_id;

  const [sort, setSort] = useState([]);
  const [page, setPage] = useState(initialDataState);
  const [selectedState, setSelectedState] = useState({});
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [editWindow, setEditWindow] = useState(false);
  const [agilePartsData, setAgilePartsData] = useState([]);
  const [enablePushBtn, setEnablePushBtn] = useState(false);
  const [filterValue, setFilterValue] = useState({});
  const [alertToPushPart, setAlertToPushPart] = useState(false);
  const [partDetails, setPartDetails] = useState({});
  const [dataChg, setDataChg] = useState(false);
  const anchor = useRef(null);

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  useEffect(() => {
    reloadGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAgilePartDetails = ({ data }) => {
    data.items.length > 0
      ? setAgilePartsData(
          data.items.map((item) =>
            Object.assign({}, formatDates(["start_active"], item))
          )
        )
      : setAgilePartsData([]);
    setLoading(false);
  };

  const reloadGrid = () => {
    setLoading(true);
    setPage(initialDataState);
    setSelectedState({});
    setEnablePushBtn(false);
    setSort([]);
    setDataChg(false);
    setSubmit(false);
    getRequest(`agile_flash?offset=0&limit=` + limit, getAgilePartDetails);
  };

  const handleEdit = () => {
    setEditWindow(true);
  };

  const openPopover = (event) => {
    setShow(!show);
  };

  const handleRowClick = (e) => {
    if (e.dataItem?.part_number) {
      if (
        e.dataItem?.commodity_code !== null ||
        e.dataItem?.commodity_group !== null
      ) {
        if (e.dataItem.pushed_to_part_master === "N") {
          setEnablePushBtn(true);
        }
      } else {
        setEnablePushBtn(false);
      }
    }
    setPartDetails({
      ...partDetails,
      part_number: e.dataItem.part_number,
      part_desc: e.dataItem.part_desc,
      commodity_group: e.dataItem.commodity_group,
      commodity_code: e.dataItem.commodity_code,
      comm_code_flg: e.dataItem.comm_code_flg,
      comm_group_flg: e.dataItem.comm_group_flg,
    });
  };

  const handlePageChange = (e) => {
    setLoading(true);
    let queryString =
      sort.length !== 0 &&
      '{"$orderby": {"' + sort[0].field + '":"' + sort[0].dir + '"}}';
    if (submit === false) {
      let urlWithoutFilter =
        sort.length === 0
          ? `agile_flash?offset=` + e.page.skip + "&limit=" + e.page.take
          : `agile_flash?offset=` +
            e.page.skip +
            "&q=" +
            encodeURIComponent(queryString) +
            "&limit=" +
            e.page.take;
      getRequest(urlWithoutFilter, getAgilePartDetails);
    }
    if (submit === true) {
      let urlWithFilter;
      let url = `agile_flash`;
      FILTER_PARAMS.forEach((param) => {
        if (filterValue.hasOwnProperty(param.id_field)) {
          if (param.paramName === "part_number_agile") {
            url += `?${param.api_field}=${
              filterValue[param.id_field]["lookup_value"]
            }`;
          }
        }
      });
      urlWithFilter =
        sort.length !== 0 ? url + "&q=" + encodeURIComponent(queryString) : url;
      if (url.includes("?")) {
        url = urlWithFilter + `&offset=${e.page.skip}&limit=${e.page.take}`;
      } else {
        url = urlWithFilter + `?offset=${e.page.skip}&limit=${e.page.take}`;
      }
      getRequest(url, getAgilePartDetails);
    }
    setTimeout(() => {
      setPage(e.page);
    }, 100);
  };

  const handleSortChange = (e) => {
    setLoading(true);
    if (dataChg === false) {
      if (e.sort && e.sort.length > 0) {
        let field = e.sort[0].field;
        let sortingOrder = e.sort[0].dir;
        const queryString =
          '{"$orderby": {"' + field + '":"' + sortingOrder + '"} }';
        getRequest(
          `agile_flash?q=` +
            encodeURIComponent(queryString) +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
          getAgilePartDetails
        );
        setSort(e.sort);
      } else {
        let dir = "asc";
        if (sort[0].dir === "asc") {
          dir = "desc";
        }
        const queryString =
          '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
        getRequest(
          `agile_flash?q=` +
            encodeURIComponent(queryString) +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
          getAgilePartDetails
        );
        setSort([{ field: sort[0].field, dir: dir }]);
      }
    } else if (dataChg === true) {
      let url;
      FILTER_PARAMS.forEach((param) => {
        if (filterValue.hasOwnProperty(param.id_field)) {
          if (param.paramName === "part_number_agile") {
            url += `?${param.api_field}=${
              filterValue[param.id_field]["lookup_value"]
            }`;
          }
        }
      });
      if (e.sort && e.sort.length > 0) {
        let field = e.sort[0].field;
        let sortingOrder = e.sort[0].dir;
        const queryString =
          '{"$orderby": {"' + field + '":"' + sortingOrder + '"} }';
        getRequest(
          `agile_flash?q=` +
            url +
            encodeURIComponent(queryString) +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
          getAgilePartDetails
        );
        setSort(e.sort);
      } else {
        let dir = "asc";
        if (sort[0].dir === "asc") {
          dir = "desc";
        }
        const queryString =
          '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
        getRequest(
          `agile_flash?q=` +
            url +
            encodeURIComponent(queryString) +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
          getAgilePartDetails
        );
        setSort([{ field: sort[0].field, dir: dir }]);
      }
    } else {
      setSort(e.sort);
    }
  };

  const onFilterSubmit = () => {
    setPage({ skip: 0, take: 10 });
    setLoading(true);
    let url = `agile_flash`;
    FILTER_PARAMS.forEach((param) => {
      if (filterValue.hasOwnProperty(param.id_field)) {
        if (param.paramName === "part_number_agile") {
          url += `?${param.api_field}=${
            filterValue[param.id_field]["lookup_value"]
          }`;
        }
      }
    });
    if (url.includes("?")) {
      url += `&offset=0&limit=${limit}`;
    } else {
      url += `?offset=0&limit=${limit}`;
    }
    getRequest(url, getAgilePartDetails);
    Object.keys(filterValue).length > 0 ? setSubmit(true) : setSubmit(false);
    setPartDetails({});
    setSelectedState({});
    setShow(false);
    setDataChg(true);
  };

  const handlePushtoPartMaster = () => {
    setAlertToPushPart(true);
  };

  const handleConfirmPush = () => {
    setAlertToPushPart(false);
    setLoading(true);
    put(`agile_flash?p_user_id=${user_id}`, partDetails, pushToPartMaster);
  };

  const pushToPartMaster = ({ data }) => {
    alert(data.p_out_err_msg);
    setLoading(false);
    reloadGrid();
  };

  return (
    <div>
      {loading ? <ProgressBar /> : ""}
      <div>
        <Button
          size="small"
          onClick={reloadGrid}
          className="app-button"
          title="Refresh"
          iconClass="k-icon k-i-reload"
        />
        <Button
          size="small"
          onClick={openPopover}
          title="Filter By"
          className="app-button"
          ref={anchor}
          iconClass={
            submit === true ? "k-icon k-i-filter-clear" : "k-icon k-i-filter"
          }
        />
        <Button
          size="small"
          onClick={handleEdit}
          className="app-button"
          title="Edit"
          disabled={Object.values(selectedState).length <= 0}
          iconClass="k-icon k-i-edit"
        />
        <Button
          size="small"
          onClick={handlePushtoPartMaster}
          className="app-button"
          title="Push"
          iconClass="k-icon k-i-plus-circle"
          disabled={!enablePushBtn}
        />
        {show && (
          <FilterPopUp
            params={FILTER_PARAMS}
            handleClose={openPopover}
            show={show}
            elementRef={anchor.current && anchor.current.element}
            handleFilterSubmit={onFilterSubmit}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
          />
        )}
      </div>
      <Tooltip openDelay={100} position="bottom" anchorElement="target">
        <Grid
          style={{ margin: 0 }}
          sortable={true}
          sort={sort}
          pageable={true}
          skip={page.skip}
          pageSize={limit}
          total={
            agilePartsData.length > 0 ? agilePartsData[0]?.resultset_size : 0
          }
          onPageChange={(e) => {
            handlePageChange(e);
          }}
          onSortChange={(e) => {
            handleSortChange(e);
          }}
          data={orderBy(
            agilePartsData?.map((item) => ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)],
            })),
            sort
          )}
          dataItemKey={DATA_ITEM_KEY}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: "single",
          }}
          onSelectionChange={onSelectionChange}
          className="tab"
          onRowClick={handleRowClick}
        >
          <GridColumn
            headerClassName="headerClass"
            field="part_number"
            title="Part Number"
            resizable={false}
            cell={(e) => GridCellToolTip(e, "part_number")}
          />
          <GridColumn
            headerClassName="headerClass"
            field="part_desc"
            title="Part Description"
            className="numberFields"
            cell={(e) => GridCellToolTip(e, "part_desc")}
          />
          <GridColumn
            headerClassName="headerClass"
            field="commodity_group"
            title="Commodity Group"
            cell={(e) => GridCellToolTip(e, "commodity_group")}
          />
          <GridColumn
            headerClassName="headerClass"
            field="commodity_code"
            title="Commodity Code"
            cell={(e) => GridCellToolTip(e, "commodity_code")}
          />
        </Grid>
      </Tooltip>
      {editWindow && (
        <EditAgileParts
          setEditWindow={setEditWindow}
          user_id={user_id}
          partDetails={partDetails}
          reloadGrid={reloadGrid}
        />
      )}
      {alertToPushPart && (
        <Dialog title={"Push"} onClose={() => setAlertToPushPart(false)}>
          <p
            style={{
              textAlign: "center",
              width: "310px",
            }}
          >
            The selected Part will be pushed to part master. Do you want to
            proceed?
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => setAlertToPushPart(false)}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleConfirmPush}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};

export default AgileParts;

import { Button } from "@progress/kendo-react-buttons";
import { Splitter } from "@progress/kendo-react-layout";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../../App";
import { getter } from "@progress/kendo-react-common";
import { Grid, GridColumn, getSelectedState } from '@progress/kendo-react-grid';
import GridCellToolTip from "../../components/GridCellToolTip/GridCellToolTip";
import { Tooltip } from "@progress/kendo-react-tooltip";
import FilterPopUp from "../../components/FilterPopUp/FilterPopUp";
import ReductionInitPopUp from "./components/ReductionInitAddPopUp";
import SavingsDetails from "./components/SavingsDetails";
import { getRequest } from "../../services/api.request";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { orderBy } from "@progress/kendo-data-query";
import { Window } from "@progress/kendo-react-dialogs";
import DownloadCOGSMassUpload from "./components/DownloadCOGSMassUpload";
import UploadReductionInitiative from "./components/UploadReductionInitiative";
import UpdateCOGSInitiativeUpload from "./components/UpdateCOGSInitiativeUpload";



const gridWidth = window.innerWidth * 0.85;
const setPercentage = (percentage) => {
    return Math.round(gridWidth / 100) * percentage;
  };


const ReductionInitative = () => {
  const FILTER_PARAMS = [
    {
      paramName: "cmatusers",
      displayValue: "GCM Name",
      id_field: "gcmname",
      api_field: "gcmname",
      filterable: true
    },
    {
      paramName: "rostatus",
      displayValue: "Status",
      id_field: "status_level",
      api_field: "status_level",
      filterable: false
    },
    {
      paramName: "probabilitylevel",
      displayValue: "Probability Level",
      id_field: "probability_level",
      api_field: "probability_level",
      filterable: false
    }
  ];

  const userContext = useContext(UserContext);
  const [selectedData, setSelectedData] = React.useState(null);
  const anchor = React.useRef(null);
  const [panes, setPanes] = React.useState([
    { size: "70%", min: "50%", collapsible: true },
    { min: "25%", collapsible: true }
  ]);
  const [sort, setSort] = useState([]);
  const [visible, setVisible] = React.useState(false);
  const [dataLabel, setDataLabel] = React.useState();
  const [loading, setLoading] = useState(false);
  const DATA_ITEM_KEY = "roid";
  const SELECTED_FIELD = "selected";
  const [dataState, setDataState] = React.useState([
  ]);
  const idGetter = getter(DATA_ITEM_KEY);
  const [selectedState, setSelectedState] = React.useState({});
  const initialDataState = {
    skip: 0
  };
  const [page, setPage] = React.useState(initialDataState);
  const limit = 10;
  const [show, setShow] = React.useState(false);
  const [initsData, setinitsData] = React.useState();
  const [submit, setSubmit] = useState(false);
  const [filterValue, setFilterValue] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [mode, setMode] = useState("Add");
  const [reductionInitative, setReductionInitative] = useState({ id: undefined, initatedQuarter: undefined, savingType: undefined, gcm: undefined });
  const [dataChg, setDataChg] = useState(false);
  const [filterURL, setFilterURL] = useState();
  const [downloadWindow, setDownloadWindow] = useState(false);
  const [uploadWindow, setUploadWindow] = useState(false);
  const [viewUploadStatus,setViewUploadStatus] = useState(false);

  const toggleDialog = (mode = null) => {
    setShow(false);
    if(mode === "Add"){
      reloadGrid();
    }
    if (mode != null) {
      setMode(mode)
    }
    setShowDialog(!showDialog);
  }
  const toggleWizard = () =>{
    setUploadWindow(!uploadWindow);
  }
  const toggleForViewDialog = ()=>{
    setViewUploadStatus(!viewUploadStatus);
  }
  const openPopover = (event) => {
    setShow(!show);
  };
  const getROReductions = ({ data }, type) => {
    setReductionInitative({ ...reductionInitative, id: undefined, initatedQuarter: undefined, savingType: undefined, gcm: undefined });
    setSelectedState({});
    setDataState(
      data.items
    );
    setLoading(false);
  }
  const getSrr = ({ data }, type) => {
    var cols = [];
    setDataLabel(data.get_eight_quarter);
    data.get_eight_quarter.map(col => cols.push({ field: col.quarter_name, title: col.quarter_name }))
  };
  const getSrr1 = ({ data }, type) => {
    setinitsData(data.items)
  };
  useEffect(() => {
    reloadGrid();
    getRequest(
      `cost_submission_quarters/lov?p_current_quarter=${userContext.curr_qrtr}`,
      getSrr
    );
    getRequest(
      `generic_lov_two?lookup_type=RO_INIT_TYPE`,
      getSrr1
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const reloadGrid = () => {
    setSelectedData(null)
    setLoading(true);
    setPage({ skip: 0 })
    getRequest('/ro_cogs_left?offset=0&limit=' + limit, getROReductions);
    setSubmit(false);
    setSort([]);
    setDataChg(false);
  }
  const reloadGridWithPageSave = () => {
    if (dataChg === true) {
      getRequest(filterURL + `&offset=${page.skip}&limit=${limit} `, getROReductions);
      setSubmit(true);
    }
    else {
      getRequest(`/ro_cogs_left?offset=${page.skip}&limit=${limit}`, getROReductions);
      setSubmit(false);
    }
    setSort([]);
    setSelectedData(null);
  }
  const onChange = (event) => {
    setPanes(event.newState);
  };
  const handleSortChange = (e) => {
    setLoading(true)
    if (dataChg === false) {
      if (e.sort && e.sort.length > 0) {
        let field = e.sort[0].field;
        let sortingOrder = e.sort[0].dir;
        const queryString = '{"$orderby": {"' + field + '":"' + sortingOrder + '"}}';
        getRequest('/ro_cogs_left?q=' + encodeURIComponent(queryString), getROReductions);
        setSort(e.sort);
      } else {
        let dir = "asc";
        if (sort[0].dir === 'asc') {
          dir = "desc";
        }
        const queryString = '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
        getRequest('/ro_cogs_left?q=' + encodeURIComponent(queryString), getROReductions);
        setSort([{ "field": sort[0].field, "dir": dir }]);
      }
    }
    else {
      setSort(e.sort);
      setLoading(false)
    }
  };
  const handlePageChange = (e) => {
    setLoading(true);
    if (submit === false) {
      let c = '/ro_cogs_left?offset=' + e.page.skip + "&limit=" + limit;
      getRequest(c, getROReductions);
    }
    if (submit === true) {
      setLoading(true);
      let url = `/ro_cogs_filter?`
      FILTER_PARAMS.forEach((param) => {
        if (filterValue.hasOwnProperty(param.id_field)) {
          let urlAppendCharacter = url.includes("?") ? `&` : `?`
          url += (filterValue[param.id_field][param.id_field] === "All") ?
            `${urlAppendCharacter}${param.api_field}=` :
            `${urlAppendCharacter}${param.api_field}=${filterValue[param.id_field][param.id_field]}`
        }
      })
      if (url.includes('?')) {
        url += `&offset=${e.page.skip}&limit=${limit}`
      } else {
        url += `?offset=${e.page.skip}&limit=${limit}`
      }
      getRequest(url, getROReductions);
    }
    setTimeout(() => {
      setPage(e.page);

    }, 100);
  };
  const handleRowClick = (e) => {
    if (visible !== true) {
      if (e.dataItem.roid) {
        setReductionInitative({ id: e.dataItem.roid, initatedQuarter: e.dataItem.initiated_quarter, savingType: e.dataItem.saving_type, gcm: e.dataItem.gcm_id });
      }
      setLoading(true);
    }
  }
  const onSelectionChange = React.useCallback(
    (event) => {
      setSelectedData(event.dataItems[event.startRowIndex])
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onFilterSubmit = () => {
    setPage({ skip: 0 })
    setLoading(true);
    let url = '/ro_cogs_filter'
    FILTER_PARAMS.forEach((param) => {
      if (filterValue.hasOwnProperty(param.id_field)) {
        let urlAppendCharacter = url.includes("?") ? `&` : `?`
        url += (filterValue[param.id_field][param.id_field] === "All") ?
          `${urlAppendCharacter}${param.api_field}=All` :
          `${urlAppendCharacter}${param.api_field}=${filterValue[param.id_field][param.id_field]}`
      }
    })
    setFilterURL(url);
    if (url.includes('?')) {
      url += `&offset=0&limit=${limit}`
    } else {
      url += `?offset=0&limit=${limit}`
    }
    Object.keys(filterValue).length > 0 ?  getRequest(url, getROReductions): getRequest('/ro_cogs_left?offset=0&limit=' + limit, getROReductions);
    Object.keys(filterValue).length > 0 ? setSubmit(true) : setSubmit(false)
    setShow(false);
    setDataChg(true);
    setReductionInitative({ ...reductionInitative, id: undefined });
  };
  return (
    <div>
      {loading ? (
        <ProgressBar />
      ) : ""}
      <Splitter panes={panes} onChange={onChange} className="splitBody">
        <div className="pane-content">
          <span>
            <Button size="small" className="app-button" title="Refresh" onClick={reloadGrid}>
              <span className="k-icon k-i-reload"></span>
            </Button>
            {
              userContext.is_scm_admin === "Y"? "":<Button size="small" className="app-button" onClick={() => toggleDialog("Add")} title="Add">
              <span className="k-icon k-i-plus"></span>
            </Button>}
            {
              // userContext.is_scm_admin === "Y"? "":<Button size="small" onClick={() => toggleDialog("Edit")} title="Edit" className="app-button"
              // disabled={selectedData === null || selectedData.gcm !== userContext.user_name || selectedData.status_level === 'Committed' || selectedData.status_level === 'Rejected' || selectedData.status_level === 'Cancel'? true : false}> 
              userContext.is_scm_admin === "Y"? "":<Button size="small" onClick={() => toggleDialog("Edit")} title="Edit" className="app-button"
              disabled={selectedData === null || 
              !userContext?.user_commodity_codes?.includes(selectedData?.commodity_code) || 
              selectedData.status_level === 'Committed' || 
              selectedData.status_level === 'Rejected' || 
              selectedData.status_level === 'Cancel'? true : false}>  
              <span className="k-icon k-i-edit"></span>
            </Button>}
            <Button size="small" className="app-button" ref={anchor} title="Filter By"  onClick={openPopover}>
              <span className={submit === true ? "k-icon k-i-filter-clear" : "k-icon k-i-filter"}></span>
            </Button>
            {
              userContext.is_scm_admin === "Y"? "":  <Button size="small" className="app-button" title="Download" 
             onClick={() => setDownloadWindow(true)} >
            <span className="k-icon k-i-download"></span>
            </Button>}
            {
              userContext.is_scm_admin === "Y"? "":
            <Button size="small" className="app-button" title="Upload"
            onClick={()=> setUploadWindow(true)} >
              <span className="k-icon k-i-upload"></span>
            </Button>}
            {
              userContext.is_scm_admin === "Y"? "":
            <Button  className="app-button" size="small" title="View Upload Status"
              onClick={(e) => { setViewUploadStatus(true); }}>
                <span  className="k-icon k-i-eye"></span>
            </Button>}
            {show && <FilterPopUp
              params={FILTER_PARAMS}
              handleClose={openPopover}
              show={show}
              elementRef={anchor.current && anchor.current.element}
              handleFilterSubmit={onFilterSubmit}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              staticLOVFlag="R"
            />}
          </span>
          <Tooltip openDelay={100} position="bottom" anchorElement="target">
            <Grid
              sortable={true}
              pageable={true}
              skip={page.skip}
              pageSize={limit}
              sort={sort}
              rowHeight={window.innerHeight * 0.067}
              data={orderBy(dataState.map((item) => ({ ...item, [SELECTED_FIELD]: selectedState[idGetter(item)] })), sort)}
              dataItemKey={DATA_ITEM_KEY} selectedField={SELECTED_FIELD}
              selectable={{ enabled: visible !== true ? true : false, drag: false, cell: false, mode: "single" }}
              onSelectionChange={onSelectionChange}
              total={dataState?.length > 0 ? dataState[0]?.resultset_size : 0}
              onRowClick={(e) => { handleRowClick(e) }}
              onSortChange={(e) => { handleSortChange(e) }}
              onPageChange={(e) => { handlePageChange(e) }}
            >
              <GridColumn headerClassName="headerClass" field='gcm' title="GCM" width={setPercentage(12)} cell={(e) => GridCellToolTip(e, 'gcm')} />
              <GridColumn headerClassName="headerClass" field='product_name' title='Product Name' width={setPercentage(12)} cell={(e) => GridCellToolTip(e, 'product_name')} />
              <GridColumn headerClassName="headerClass" field='commodity_code' title='Commodity Code' width={setPercentage(12)} cell={(e) => GridCellToolTip(e, 'commodity_code')} />
              <GridColumn headerClassName="headerClass" field='item' title='Item' width={setPercentage(10)} cell={(e) => GridCellToolTip(e, 'item')} />
              <GridColumn headerClassName="headerClass" field='bu' title='BU' width={setPercentage(10)} cell={(e) => GridCellToolTip(e, 'bu')} />
              <GridColumn headerClassName="headerClass" field='idea_name' title='Idea Name' width={setPercentage(15)} cell={(e) => GridCellToolTip(e, 'idea_name')} />
              <GridColumn headerClassName="headerClass" field='proposed_change' title='Proposed Change' width={setPercentage(15)} cell={(e) => GridCellToolTip(e, 'proposed_change')} />
              <GridColumn headerClassName="headerClass" field='initiative_type' title='Initiative Type' width={setPercentage(8)} cell={(e) => GridCellToolTip(e, 'initiative_type')} />
              <GridColumn headerClassName="headerClass" field='saving_type' title='Savings Type' width={setPercentage(13)} cell={(e) => GridCellToolTip(e, 'saving_type')} />
              <GridColumn headerClassName="headerClass" field='probability_level' title='Probability Level' width={setPercentage(8)} cell={(e) => GridCellToolTip(e, 'probability_level')} />
              <GridColumn headerClassName="headerClass" field='status_level' title='Status' width={setPercentage(8)} />
              <GridColumn headerClassName="headerClass" field='initiated_quarter' title='Initiated Quarter' width={setPercentage(10)} />
            </Grid>
          </Tooltip>
        </div>
        <div className="pane-content">
          <div className='commodity-views-header'>
            <strong>  <span>Savings Details</span> </strong>
          </div>
          <SavingsDetails ReductionInitativeId={reductionInitative.id} ReductionInitatedQuarter={reductionInitative.initatedQuarter}
            ReductionSavingType={reductionInitative.savingType} ReductionGCMId={reductionInitative.gcm} setLoading={setLoading} />
        </div>
      </Splitter>
      {
        showDialog && <ReductionInitPopUp
          mode={mode}
          selectedData={selectedData}
          toggleDialog={toggleDialog}
          dataLabel={dataLabel}
          reloadGrid={reloadGridWithPageSave}
          initsData1={initsData} />
      }
      {
        uploadWindow && <UploadReductionInitiative
        setLoading={setLoading}
        toggleWizard={toggleWizard}
        // toggleWizard={(e) => {
        //   setUploadWindow(!uploadWindow);
        // }}
        />       
      }
        {downloadWindow && (
        <div className="window-costAllocation">
          <Window
            style={{ zIndex: "1", height: "247px", width: "450px" }}          
            title={"Download Initiative"}
            onClose={() => setDownloadWindow(false)}
            minimizeButton={() => null}
            maximizeButton={() => null}
          >
            <DownloadCOGSMassUpload
             user_id={userContext.user_id}
              nextEightQuarters={dataLabel}
            />
          </Window>
        </div>
      )}
      {
        viewUploadStatus && <UpdateCOGSInitiativeUpload
        toggleForViewDialog = {toggleForViewDialog}>
        </UpdateCOGSInitiativeUpload>      }
    </div>
  );
}
export default ReductionInitative;
import axios from "axios";
import { apiUrl } from "../environment/environment";
import tokenService from "./token.service";

export const publicRequest = axios.create({
    baseURL: apiUrl
})

const privateRequest = axios.create({
    baseURL: apiUrl
})

function setToken(payload){
    let expiry_date = new Date();
    expiry_date.setSeconds((expiry_date.getSeconds() + payload.expires_in) - 60);
    localStorage.setItem("token", JSON.stringify({access_token: payload.access_token, expiry_date: expiry_date}));  
}

privateRequest.interceptors.request.use(
    (config) => {
      const token = tokenService.getLocalAccessToken();
      if (token) {
          config.headers["Authorization"] = `Bearer ${token.access_token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

 privateRequest.interceptors.response.use(
        response => response,
         error => {
            if (error.response.status !== 401) {
                return Promise.reject(error);
            }
            //axios.interceptors.response.eject(interceptor);
            return tokenService.updateLocalAccessToken()
            .then(response => {
                setToken(response.data);
                error.response.config.headers['Authorization'] = 'Bearer ' + response.data.access_token;
                return axios(error.response.config);
            }).catch(error => {
                return Promise.reject(error);
            })
        }
    );


export default privateRequest;

